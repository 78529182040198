/* Analytics Title */
.analytics-title {
    font-size: 50px;
    font-weight: bold;
    font-family: 'Norwester', sans-serif;
    text-align: center;
    margin-top: 200px;
    color: #443a90;
    position: relative;
    transition: 0.4s ease;
}

.analytics-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.analytics-content-container h1{
    font-size: 50px;
    color: #443a90;
}

.symbol-selector-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #443a90;
}

.for-mobile {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.plot-container {
    width: 90%;
    height: 500px;
    margin: auto;
}

.predict-button {
    background-color: #443a90;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    margin-top: 24px;
    cursor: pointer;
    border-radius: 6px;
    transition-duration: 0.4s;
    border: 1px solid white;
}

.symbol-input-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 12px;
}

.radio-input input {
    display: none;
  }
  
  .radio-input {
    --container_width: 300px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #fff;
    color: #000000;
    width: var(--container_width);
    overflow: hidden;
    border: 1px solid rgba(53, 52, 52, 0.226);
  }
  
  .radio-input label {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: 14px;
  }
  
  .selection {
    display: none;
    position: absolute;
    height: 100%;
    width: calc(var(--container_width) / 5);
    z-index: 0;
    left: 0;
    top: 0;
    transition: .15s ease;
  }
  
  .radio-input label:has(input:checked) {
    color: #fff;
  }
  
  .radio-input label:has(input:checked) ~ .selection {
    background-color: #443a90;
    display: inline-block;
  }
  
  .radio-input label:nth-child(1):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 0/5));
  }
  
  .radio-input label:nth-child(2):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 1/5));
  }
  
  .radio-input label:nth-child(3):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 2/5));
  }
  
  .radio-input label:nth-child(4):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 3/5));
  }
  .radio-input label:nth-child(5):has(input:checked) ~ .selection {
    transform: translateX(calc(var(--container_width) * 4/5));
  }

.predict-button:hover {
    background-color: white;
    color: #443a90;
    border: 1px solid #443a90;
}
/* For WebKit browsers like Chrome and Safari */
input[type=range] {
    -webkit-appearance: none;
}


input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    background: #ddd;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #443a90;
    margin-top: -4px;
}

input[type=range]:focus {
    /*outline: none;*/
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ddd;
}


/* Analytics Text */
.analytics-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4%;
    margin-top: 1%;
    padding: 20px;
    font-size: 22px;
    background: linear-gradient(-1deg, rgba(153, 102, 255, 0.1), rgba(170, 128, 255, 0.05));
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: 0.4s ease;
    color: #3d3b48;
}

/* Hover Effect */
.analytics-text:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}


/* Media Query for smaller screens */
@media (max-width: 768px) {
    .analytics-title {
        font-size: 50px;
        margin-top: 160px;
    }
    .analytics-text {
        font-size: 21px;
        margin: 4%;
        margin-bottom: 6%;
    }
}
