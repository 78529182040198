.wave-container {  
    margin-top: 150px;
    background-color: #ffffff;
    position: relative;
}
  
/* Wave divider */
.custom-shape-divider-bottom-1708470298 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.landingContainer video {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Adjust the height as needed */
    object-fit: cover; /* Cover the container without losing aspect ratio */
    width: 50%;
    margin-top: 25px;
    margin-bottom: 10px;
  }

.custom-shape-divider-bottom-1708470298 svg {
    position: relative;
    display: block;
    width: calc(121% + 1.3px);
    height: 126px;
}

.custom-shape-divider-bottom-1708470298 .shape-fill {
    fill: #443A90;
}

.home-page-block-text {
    width: 50%;
}
/* Wave divider */
@media(max-width:850px) {
    .wave-container::before {    
      height: 38.5px
    }  
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #443a90;
    margin: 0;
    color: white;
    min-height: 100%;
    padding: 10px;
}

.landingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.landingContainer img {
    width: 50%;
    margin-top: 25px;
    margin-bottom: 10px;
}

.titleContainer h1 {
    font-size: 100px;
    font-family: 'Norwester', sans-serif;
    margin-top: 0px;
    color: #443a90;
    text-align: center;
    margin-bottom: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonContainer button {
    position: relative;
    display: inline-block;
    margin: 15px;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #443a90;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 3px solid #443a90;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #443a90;

    font-size: large;
}
  
.buttonContainer button:hover {
    color: white;
    background-color: #443a90;
    box-shadow: inset 0 -100px 0 0 #443a90;
}
  
.buttonContainer button:active {
    transform: scale(0.9);
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 25px;
    flex: 1;
    padding: 20px, 20px, 20px, 20px;
    font-size: 150%;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .left {
    order: 1;
  }
  
.right {
    order: 2;
}

.row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.home-page-image {
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 20px;
}

.home-image {
    border-radius: 10px;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-image.left:hover {
    transform: translateY(-25px) rotate(1deg) skew(0deg, 2.5deg);
    box-shadow: -15px 15px 0px rgba(0, 0, 0, 0.3);
}

.home-image.right:hover {
    transform: translateY(-15px) rotate(-1deg) skew(0deg, -2.5deg);
    box-shadow: 15px 15px 0px rgba(0, 0, 0, 0.3);
}

.home-page-info-block-title {
    position: relative;
    font-size: 400%;
    font-weight: 400;
    font-style: oblique 1deg;
    text-align: center;
    text-decoration: none;
    transition: color 0.3s ease;
}

.home-page-info-block-title::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 120%;
    height: 2px;
    background-color: #c8c5e0;
    transition: width 0.3s ease;
}

.home-page-info-block-title:hover {
    color: #f5f4ff; 
}

.home-page-info-block-title:hover::after {
    width: 100%; 
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.home-page-image {
    height: auto; /* Maintain aspect ratio for the image */
    display: block;
}

.home-page-row-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 1200px;
    padding-bottom: 75px;
    align-items: center;
}
  
@media (max-width: 768px) {
    .home-page-info-block-title::after{
        width: 105%;
    }
    .landingContainer {
        margin: auto;
        width: 100%;
    }
    .landingContainer video {
        width: 90%;
    }
    .buttonContainer {
        flex-direction: column;
    }
    .titleContainer h1 {
        font-size: 80px;
    }
    .home-page-block-text {
        width: 100%;
    }
    .home-page-row-container {
        padding: 0;
    }
    .text {
        padding: 0;
        font-size: 18px;
        margin: 0 !important
    }
}