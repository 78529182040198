.nav {
    /* 
    Uncomment if you want a background to navbar
    background-color: #333; 
    */
    padding: 10px 0;
}
  
.nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}
  
.nav-item {
    margin-right: 20px;
}

.nav-button {
    font-size: larger;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    color: #34323d;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    position:relative; 
    overflow: hidden;
}

.nav-button::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: right 0.5s ease, background-color 0.5s ease;
}

.nav-button:hover::before,
/* If you don't want the active to have the underline, 
    remove the line directly below */
.nav-button.active::before {
    background-color: #443A90; /* Underline's colour, if changing text, change this! */
    right: 0; 
}

.nav-button:hover {
    color: #443A90;
    transition: color 0.3s ease-in-out, transform 0.5s ease-out; 
    transform: scale(1.1);
}

.nav-button:not(:hover) {
    transition: transform 0.5s ease-out; 
}

.nav-button.active {
    color: #594dba;
}


/* Styling for the divider between nav bar and page content */
hr {
    border: none;
    height: 14px;
    background-color: #cccccc00;
}
  
  

/*
 *    Mobile Navbar
 *
 *    Creates a hamburger menu with vertically listed items
 */
.menu-icon {
    display: block;
    cursor: pointer;
}
  
.menu-icon div {
    width: 25px;
    height: 3px;
    background-color: #34323d;
    margin: 5px;
    transition: transform 0.3s ease;
}
  
#menu-toggle {
    display: none;
}
  
#menu-toggle:checked ~ .menu {
    display: block;
}
  
.menu {
    display: none;
    font-size: 16px !important
}
  
.menu-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}
  
.menu-item {
    margin-bottom: 10px;
}
  
.menu-button {
    text-decoration: none;
    color: #34323d;
    font-weight: bold;
    font-size: 18px;
    transition: color 0.3s ease;
}
  
.menu-button:hover {
    color: #443A90;
}

/* Lists nav-items vertically */
.vertical {
    flex-direction: column;
}

/* Adjust hamburger icon animation */
.menu-icon div:first-child {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease;
}

.menu-icon div:nth-child(2) {
    transform-origin: 50% 50%;
    transition: none;
}

.menu-icon div:last-child {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease;
}

/* Change hamburger icon to an "X" when checked */
#menu-toggle:checked ~ .menu-icon div:first-child {
    transform: rotate(45deg);
}

#menu-toggle:checked ~ .menu-icon div:nth-child(2) {
    transform: scaleX(0);
}

#menu-toggle:checked ~ .menu-icon div:last-child {
    transform: rotate(-45deg);
}

